// COLORS
$color-white: #fff;
$color-black: #2b2b2b;
$color-gray: #d9d9d9;
$color-gray-light: #f4f4f4;
$color-gray-dark: #c6c6c6;
$color-gray-darker: #696969;
$color-primary: #ffcf3e;
$color-primary-secondary: #feba12;
$color-primary-hover: #ffe160;
$color-primary-bg: #fff6d9;
$color-link: $color-black;
$color-link-hover: #6c6c6c;
$color-divider: $color-gray-dark;

// FONTS
$font-family-font-awesome: "Font Awesome 6 Free";
$font-family-main: sans-serif;

// Bootstrap color overrides
$secondary: $color-gray-dark;
$primary: $color-primary;
$dark: $color-primary-secondary;
$font-family-sans-serif: $font-family-main;

// sizes
$font-size-xl: 3.07rem;
$font-size-xl-lg: 2.4rem;
$font-size-lg: 2rem;
$font-size-md: 1.5rem;
$font-size-sm: 1.125rem;
$font-size-xs: 1rem;
$font-size-xxs: 0.9rem;
$font-size-xxxs: 0.8rem;
$font-size-xxxxs: 0.7rem;

// screen size break points
// using rem for better zoom responsiveness
$screen-width-phone-sm: 23.75rem; // 380px
$screen-width-phone: 36rem; // 576px
$screen-width-tablet: 48rem; // 768px
$screen-width-tablet-landscape: 62rem; // 992px
$screen-width-desktop: 75rem; // 1200px
$screen-width-desktop-large: 87rem; // 1400px
$screen-sizes: (
	null,
	phone-down,
	tablet-up,
	tablet-landscape-down,
	tablet-landscape-up,
	desktop-up
);

// SVG inlines
$svg-arrow-right: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.17 10.9"><path d="M13.71 4.74 9.46.49a1.03 1.03 0 0 0-1.44 0c-.39.4-.39 1.04 0 1.44l2.51 2.53H1.25c-.56 0-1.02.45-1.02 1.02S.68 6.5 1.25 6.5h9.29L8.03 9.01A1.01 1.01 0 0 0 8 10.45c.39.41 1.04.42 1.44.03l.03-.03 4.24-4.24a1.02 1.02 0 0 0 0-1.44Z" style="fill:%232b2b2b;stroke-width:0"/></svg>');
$svg-external-link: url('data:image/svg+xml,<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.02 14.85"><defs><style> .cls-1{fill:%232b2b2b;stroke-width:0} </style></defs><path id="Path_7115" class="cls-1" d="m8.88.13 2.06 2.06-6.32 6.32a1.33 1.33 0 1 0 1.88 1.88l6.32-6.32 2.05 2.05V.13H8.88Z"/><path id="Path_7116" class="cls-1" d="M12.87 8.12c-.37 0-.67.3-.67.67v4.65H1.55V2.77H6.2a.67.67 0 0 0 0-1.34H1.55c-.74.01-1.34.6-1.34 1.34v10.66c0 .74.6 1.33 1.33 1.33H12.2c.74 0 1.33-.6 1.33-1.33V8.79a.66.66 0 0 0-.66-.67Z"/></svg>');
$svg-pdf: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.14 46.6"><path d="M23.07.45A22.85 22.85 0 1 0 45.92 23.3 22.85 22.85 0 0 0 23.07.45" style="stroke-width:0;fill:%23ffcf3d"/><path d="M18.85 26.86h5.44a.83.83 0 0 1 0 1.66h-5.44a.83.83 0 0 1 0-1.66m0-4.31h8.44a.83.83 0 0 1 0 1.66h-8.44a.83.83 0 0 1 0-1.66m6.68-6.74c.1 0 .2.04.27.11l3 3a.38.38 0 0 1-.27.65h-1.32a1.98 1.98 0 0 1-2.05-1.92v-1.46c0-.21.17-.38.38-.38Zm-7.91-2.47a2.86 2.86 0 0 0-2.96 2.77v14.37a2.86 2.86 0 0 0 2.95 2.77h10.9a2.86 2.86 0 0 0 2.96-2.76v-9.4c0-.99-.39-1.94-1.09-2.65l-4.01-4.01a3.73 3.73 0 0 0-2.64-1.09h-6.12Z" style="fill:%23000;stroke-width:0"/></svg>');
