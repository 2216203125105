@import "src/scss/abstracts/variables";
@import "src/scss/abstracts/mixins";

.intro-split-view-wrap {
	position: relative;
	overflow: hidden;

	&--wide {
		.container {
			max-inline-size: none;

			@media (max-width: toRem(1500)) {
				max-inline-size: inherit;
			}
		}
	}
}

.intro-split-view-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 101%;
	z-index: 0;
	display: flex;

	&--reverse {
		flex-direction: row-reverse;
	}

	> div {
		flex: 1;
	}

	&__img {
		@include bgImg();

		@include for-size(tablet-down) {
			background: none !important;
		}
	}

	&--gray {
		background-color: $color-gray-light;
	}
}

.intro-split-view {
	position: relative;
	z-index: 1;
	display: flex;

	@include for-size(tablet-down) {
		flex-direction: column;
	}

	&--gray {
		background-color: $color-gray-light;
	}

	&--reverse {
		flex-direction: row-reverse;

		@include for-size(tablet-down) {
			flex-direction: column;
		}
	}

	&--mobile-reverse {
		@include for-size(tablet-down) {
			flex-direction: column-reverse;
		}
	}

	&--roundededges {
		align-items: center;

		@include for-size(tablet-down) {
			gap: toRem(60);
		}
		@include for-size(phone-down) {
			gap: toRem(40);
		}
	}
}

.intro-split-view__text {
	width: 50%;

	@include for-size(tablet-down) {
		width: 100%;
	}

	&--wide {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include for-size(tablet-down) {
			align-items: flex-start;
		}

		.intro-split-view__text-inner {
			max-width: toRem(570);

			@include for-size(tablet-down) {
				max-width: none;
			}
		}
	}

	&--fullbleed {
		padding: toRem(180) toRem(100) toRem(180) 0;
		

		@media (max-width: toRem(2000)) {
			padding: toRem(160) toRem(90) toRem(160) 0;
		}
		@media (max-width: toRem(1400)) {
			padding: toRem(90) toRem(90) toRem(90) 0;
		}
		@media (max-width: toRem(1200)) {
			padding: toRem(60) toRem(60) toRem(60) 0;
		}
		@include for-size(tablet-down) {
			padding: toRem(30) 0;
		}

		&.less-padding {
			padding: toRem(60) toRem(60) toRem(60) 0;

			@include for-size(tablet-down) {
				padding: toRem(30) 0;
			}
		}
	}

	&--roundededges {
		padding: 0 toRem(100) 0 0;

		@media (max-width: toRem(2000)) {
			padding: 0 toRem(90) 0 0;
		}
		@media (max-width: toRem(1200)) {
			padding: 0 toRem(60) 0 0;
		}
		@include for-size(tablet-down) {
			padding: 0;
		}
	}

	&--reverse {
		padding-left: toRem(100);
		padding-right: 0;

		@media (max-width: toRem(1200)) {
			padding-left: toRem(60);
		}
		@include for-size(tablet-down) {
			padding-left: 0;
		}
	}

	&-title {
		h1 {
			font-size: toRem(60);
			line-height: 1.2;
			margin-bottom: toRem(20);

			&.larger {
				font-size: toRem(45);
			}
			&.large {
				font-size: toRem(30);
			}

			@include for-size(tablet-landscape-down) {
				font-size: toRem(36);

				&.larger {
					font-size: toRem(30);
				}
				&.large {
					font-size: toRem(24);
				}
			}
		}
	}

	&-description {
		p {
			font-size: toRem(22);

			&:last-child {
				margin-bottom: 0;
			}

			@include for-size(tablet-landscape-down) {
				font-size: toRem(20);
			}
		}
	}
}

.intro-split-view__image {
	width: 50%;

	@include for-size(tablet-down) {
		width: 100%;
	}

	&-img {
		@include bgImg();
	}

	&--fullbleed {
		display: none;

		@include for-size(tablet-down) {
			display: block;
			width: 100vw;
			margin-left: toRem(-30);
		}

		.intro-split-view__image-img {
			width: 100%;
			height: 100%;

			@include for-size(tablet-down) {
				padding-top: 70%;
			}
		}
	}

	&--roundededges {
		background: none !important;

		.intro-split-view__image-img {
			border-radius: toRem(20);
			width: 100%;
			aspect-ratio: 1.4;
		}
	}
}

.intro-split-view__video {
	width: 50%;

	&-wrap {
		height: 100%;
		display: flex;
		align-items: center;
	}
	

	@include for-size(tablet-down) {
		width: 100%;
	}

	&-description {
		font-size: toRem(18);
		margin-top: toRem(10);
	}
}
